import { gql, useMutation } from '@apollo/client'

export const START_MEMBERSHIP = gql`
  mutation startMembership($membershipId: Int!) {
    startMembership(membershipId: $membershipId) {
      success
      error
    }
  }
`

const REJOIN_MEMBERSHIP = gql`
  mutation rejoinMembership($membershipId: Int!) {
    rejoinMembership(membershipId: $membershipId) {
      success
      error
    }
  }
`

export function useStartMembership({
  isRejoin,
  onCompleted = () => {},
  onError = () => {}
}: {
  isRejoin: boolean
  onCompleted?: (response?: any) => void
  onError?: () => void
}) {
  const mutation = isRejoin ? REJOIN_MEMBERSHIP : START_MEMBERSHIP
  const [startMembership, rest] = useMutation(mutation, {
    onCompleted,
    onError,
    notifyOnNetworkStatusChange: true
  })

  return {
    startMembership: (variables: { membershipId: number }) =>
      startMembership({ variables }),
    ...rest
  }
}
