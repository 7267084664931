import { CuiIcon } from 'front-lib'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFlag } from '@unleash/proxy-client-react'
import { useHistory } from 'react-router-dom'

import {
  FF_ENABLE_UPDATE_TO_STANDARD_PLAN_FEATURE,
  FF_ENABLE_RETURN_TO_WEEKLY_PLAN_FEATURE,
  FF_RETURN_TO_LAST_REGULAR_FROM_BOX_PLAN_FEATURE
} from 'src/constants/feature-flags'
import { RoutePaths } from 'src/routes/routePaths'
import { SettingSection } from '../../../../components/SettingsCard'
import {
  isEconomicPlanSelector,
  isMonthlyPlanSelector,
  isBoxPlanSelector,
  getAllowPlanChange
} from 'src/redux-api-bridge/selectors'
import { UpgradeToStandardPlan } from '../UpgradeToStandardPlan/UpgradeToStandardPlan'
import { ReturnToWeeklyPlan } from '../ReturnToWeeklyPlan/ReturnToWeeklyPlan'
import { useMobileDevice } from '../../../../hooks/useMobileDevice'
import tracking from '../../../../shared/tracking'
import { usePlanConfiguration } from '../../hooks/usePlanConfiguration'
import { EconomicPlanTag } from '../EconomicPlanTag/EconomicPlanTag'
import { useChangePlanModal } from 'src/hooks/useChangePlanModal'
import { ReturnToLastRegularPlan } from '../ReturnToLastRegularPlan/ReturnToLastRegularPlan'

export function ChangePlanSettings() {
  const { t } = useTranslation()
  const history = useHistory()
  const isMobileDevice = useMobileDevice()
  const enableUpdateToStandardPlanFeature = useFlag(
    FF_ENABLE_UPDATE_TO_STANDARD_PLAN_FEATURE
  )
  const enableReturnToWeeklyPlanFeature = useFlag(
    FF_ENABLE_RETURN_TO_WEEKLY_PLAN_FEATURE
  )

  const enableReturnToLastRegularPlanFeature = useFlag(
    FF_RETURN_TO_LAST_REGULAR_FROM_BOX_PLAN_FEATURE
  )

  const {
    isModalEnabled: changePlanModalEnabled,
    open: openChangePlanModal
  } = useChangePlanModal()

  const {
    deliveriesPerWeek,
    pricePerMeal,
    isPlanWithDeliveryFee,
    planName
  } = usePlanConfiguration()

  const isMonthlyPlan = useSelector(isMonthlyPlanSelector)
  const isEconomicPlan = useSelector(isEconomicPlanSelector)
  const isBoxPlan = useSelector(isBoxPlanSelector)
  const allowPlanChange = useSelector(getAllowPlanChange)

  const showUpgrageToStandardPlan =
    enableUpdateToStandardPlanFeature && isEconomicPlan

  const showReturnToWeeklyPlan =
    enableReturnToWeeklyPlanFeature && isMonthlyPlan

  const showReturnToLastRegularPlan =
    enableReturnToLastRegularPlanFeature && isBoxPlan

  function onChangePlan() {
    tracking.track('Change Plan')
    if (changePlanModalEnabled) {
      openChangePlanModal()
    } else {
      history.push(RoutePaths.CHANGE_PLAN)
    }
  }

  return (
    <>
      {isMobileDevice && isEconomicPlan && allowPlanChange && (
        <EconomicPlanTag />
      )}

      {showUpgrageToStandardPlan && allowPlanChange && (
        <UpgradeToStandardPlan />
      )}

      {showReturnToWeeklyPlan && allowPlanChange && <ReturnToWeeklyPlan />}

      {showReturnToLastRegularPlan && allowPlanChange && (
        <ReturnToLastRegularPlan />
      )}

      {!isBoxPlan && (
        <SettingSection
          className="cookunity__my_plan_section"
          title={
            <span className="title_new_plan_meals">
              {planName}
              <span className="title_new_plan_meals_week">
                {' / '}
                {isPlanWithDeliveryFee
                  ? t('pages.my_plan.week')
                  : t('pages.my_plan.weekly')}
              </span>
            </span>
          }
          subtitle={
            isPlanWithDeliveryFee ? (
              <span>
                <span className="subtitle_new_plan_price">
                  {`$${pricePerMeal} ${t('pages.my_plan.per_meal')}`}
                </span>
                <span className="subtitle_new_plan_delivery">
                  {` + ${t('pages.my_plan.taxes_and_fees')}`}
                </span>
              </span>
            ) : (
              t('pages.my_plan.plan_subtitle', {
                pricePerMeal,
                deliveriesPerWeek,
                plural: deliveriesPerWeek > 1 ? 'ies' : 'y'
              })
            )
          }
          withActionButton={allowPlanChange}
          buttonLabel={
            <>
              <CuiIcon name="sync" /> {t('pages.my_plan.change_plan')}
            </>
          }
          buttonClassName="cookunity__my_plan_section-cancel-plan-secondary"
          onActionClick={onChangePlan}
        />
      )}
    </>
  )
}
