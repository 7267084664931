import { FILTER_IDS } from '../Filters/constants'

import MenuAll from '../../../../../assets/icons/menuby/menu-all.svg'
import Sides from '../../../../../assets/icons/menuby/sides.svg'
import Breakfast from '../../../../../assets/icons/menuby/breakfast.svg'
import YouveTried from '../../../../../assets/icons/menuby/you_ve-tried.svg'
import Favorites from '../../../../../assets/icons/menuby/favorites.svg'

import FingerFood from '../../../../../assets/svg/filters/sides-finger-food.svg'
import Grains from '../../../../../assets/svg/filters/sides-grains-starch.svg'
import Protein from '../../../../../assets/svg/filters/sides-protein.svg'
import Pasta from '../../../../../assets/svg/filters/sides-pasta-bread.svg'
import SidesSoup from '../../../../../assets/svg/filters/sides-soups.svg'
import Veggies from '../../../../../assets/svg/filters/sides-veggies-salads.svg'

import Pizza from '../../../../../assets/icons/menuby/pizza.svg'
import Balanced from '../../../../../assets/icons/menuby/Balanced.svg'
import Burgers from '../../../../../assets/icons/menuby/Burgers.svg'
import Noodles from '../../../../../assets/icons/menuby/Noodles.svg'
import ProteinStarch from '../../../../../assets/icons/menuby/Protein-and-starch.svg'
import Salad from '../../../../../assets/icons/menuby/Salad.svg'
import Soup from '../../../../../assets/icons/menuby/Soup.svg'
import Taco from '../../../../../assets/icons/menuby/Taco.svg'
import FamilyFavorites from '../../../../../assets/icons/menuby/family-favorites.svg'
import Veggie from '../../../../../assets/icons/menuby/Veggie.svg'

import Healthy from '../../../../../assets/icons/menuby/healthy.svg'

import Foryou from '../../../../../assets/icons/menuby/foryou.svg'
import GreenEating from '../../../../../assets/icons/menuby/green-eating.svg'
import AddAndSave from '../../../../../assets/icons/menuby/Add-and-Save.svg'

export const CATEGORY_PATTERN = {
  SIDES: 'category_sides',
  BREAKFAST: 'category_breakfast'
}

export const CATEGORY_LABEL = {
  SIDES: 'Sides',
  BREAKFAST: 'Breakfast'
}

export const GENERIC_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    hasSub: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.CHEF_SKU_PROMOTION,
    name: 'Add & Save',
    pattern: 'discount_by_sku',
    label: 'Add & Save',
    image: AddAndSave,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_HEALTHY,
    name: 'Wellness',
    label: 'Wellness',
    image: Healthy,
    withTabs: false,
    pattern: 'healthy',
    mealTypeFilter: true,
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.FAVORITES,
    name: 'Favorites',
    pattern: 'favorite',
    label: 'Favorites',
    image: Favorites,
    isFavorite: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.YOUVE_TRIED,
    name: 'Order Again',
    pattern: 'alreadyRequested',
    label: 'Order Again',
    image: YouveTried,
    withTabs: false
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SALADS_AND_BOWLS,
    name: 'Salads/Bowls',
    pattern: 'Salads & Bowls',
    label: 'Salads/Bowls',
    image: Salad,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_BALANCED_PLATE,
    name: 'Balanced',
    pattern: 'Balanced Plate',
    label: 'Balanced',
    image: Balanced,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.BREAKFAST,
    name: 'Breakfast',
    image: Breakfast,
    pattern: CATEGORY_PATTERN.BREAKFAST,
    withTabs: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PASTA_AND_NOODLES,
    name: 'Pasta',
    pattern: 'Pasta & Noodles',
    label: 'Pasta',
    image: Noodles,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_TACOS_AND_BURRITOS,
    name: 'Tacos/Burritos',
    pattern: 'Tacos & Burritos',
    label: 'Tacos/Burritos',
    image: Taco,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.SIDES_EXPERIMENT,
    name: 'Mix & Match',
    pattern: 'sides_without_proteins',
    image: Sides,
    withTabs: true,
    fieldToFilter: 'filter_by',
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.SIDES_PROTEIN_ONLY,
    name: 'Protein Only',
    pattern: 'sides_with_proteins',
    image: Protein,
    withTabs: false,
    fieldToFilter: 'filter_by',
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_VEG_MEALS,
    name: 'Veggie',
    pattern: 'Veg Meals',
    label: 'Veggie',
    image: Veggie,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SANDWICH_AND_WRAPS,
    name: 'Sandwiches',
    pattern: 'Sandwich & Wraps, Burgers',
    label: 'Sandwiches',
    image: Burgers,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PROTEIN_STARCH,
    name: 'Protein/Starch',
    pattern: 'Protein / Starch',
    label: 'Protein/Starch',
    image: ProteinStarch,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PIZZA,
    name: 'Pizza',
    pattern: 'Pizza',
    label: 'Pizza',
    image: Pizza,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SOUPS_AND_STEWS,
    name: 'Soups/Stews',
    pattern: 'Soups & Stews',
    label: 'Soups/Stews',
    image: Soup,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'typesTags'
  }
]

export const SIDES_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.SIDES_ALL,
    image: Sides,
    name: 'All sides',
    label: 'All sides',
    pattern: CATEGORY_PATTERN.SIDES
  },
  {
    id: FILTER_IDS.SIDES_VEGGIES_SALADS,
    image: Veggies,
    name: 'Veggies and Salads',
    label: 'Veggies and Salads',
    pattern: 'Veggies & Salads'
  },
  {
    id: FILTER_IDS.SIDES_GRAINS_STARCH,
    image: Grains,
    name: 'Grains and Starch',
    label: 'Grains and Starch',
    pattern: 'Grains & Starches'
  },
  {
    id: FILTER_IDS.SIDES_PASTA_BREAD,
    image: Pasta,
    name: 'Pasta and Bread',
    label: 'Pasta and Bread',
    pattern: 'Pasta & Bread'
  },
  {
    id: FILTER_IDS.SIDES_FINGER_FOOD,
    image: FingerFood,
    name: 'Finger Food',
    label: 'Finger Food',
    pattern: 'Finger Foods'
  },
  {
    id: FILTER_IDS.SIDES_SOUPS,
    image: SidesSoup,
    name: 'Side Soups',
    label: 'Side Soups',
    pattern: 'Side Soups'
  },
  {
    id: FILTER_IDS.SIDES_PROTEIN,
    image: Protein,
    name: 'Protein',
    label: 'Protein',
    pattern: 'Protein'
  }
]

export const SIDES_EXPERIMENT_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.SIDES_ALL,
    image: Sides,
    name: 'All',
    label: 'All',
    pattern: 'sides_without_proteins',
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.SIDES_VEGGIES_SALADS,
    image: Veggies,
    name: 'Veggies and Salads',
    label: 'Veggies and Salads',
    pattern: 'Veggies & Salads'
  },
  {
    id: FILTER_IDS.SIDES_GRAINS_STARCH,
    image: Grains,
    name: 'Grains and Starch',
    label: 'Grains and Starch',
    pattern: 'Grains & Starches'
  },
  {
    id: FILTER_IDS.SIDES_PASTA_BREAD,
    image: Pasta,
    name: 'Pasta and Bread',
    label: 'Pasta and Bread',
    pattern: 'Pasta & Bread'
  },
  {
    id: FILTER_IDS.SIDES_FINGER_FOOD,
    image: FingerFood,
    name: 'Finger Food',
    label: 'Finger Food',
    pattern: 'Finger Foods'
  },
  {
    id: FILTER_IDS.SIDES_SOUPS,
    image: SidesSoup,
    name: 'Side Soups',
    label: 'Side Soups',
    pattern: 'Side Soups'
  }
]

export const BREAKFASTS_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.BREAKFAST_ALL,
    image: Breakfast,
    name: 'All breakfast',
    label: 'All breakfast',
    pattern: CATEGORY_PATTERN.BREAKFAST
  },
  {
    id: FILTER_IDS.BREAKFAST_EGGS,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-eggs.svg',
    name: 'Eggs',
    label: 'Eggs',
    pattern: 'Eggs'
  },
  {
    id: FILTER_IDS.BREAKFAST_PANCAKES_WAFFLES,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-pancakes-waffles.svg',
    name: 'Pancakes & Waffles',
    label: 'Pancakes & Waffles',
    pattern: 'Pancakes & Waffles'
  },
  {
    id: FILTER_IDS.BREAKFAST_YOGURT_GRANOLA,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-yogurt-granola.svg',
    name: 'Yogurt, Granola & Oatmeal',
    label: 'Yogurt, Granola & Oatmeal',
    pattern: 'Yogurt'
  },
  {
    id: FILTER_IDS.BREAKFAST_BREAD_PASTRIES,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-bread-pastries.svg',
    name: 'Bread & Pastries',
    label: 'Bread & Pastries',
    pattern: 'Bread & Pastries'
  },
  {
    id: FILTER_IDS.BREAKFAST_SMOOTHIES_JUICES,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-juices.svg',
    name: 'Smoothies & Juices',
    label: 'Smoothies & Juices',
    pattern: 'Smoothies & Juices'
  },
  {
    id: FILTER_IDS.BREAKFAST_BARS,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-bar.svg',
    name: 'Bars',
    label: 'Bars',
    pattern: 'Bars'
  }
]

export const FILTER_BY_FIELD = {
  TAGS: 'typesTags',
  FILTER_BY: 'filter_by'
}

export const MENU_ITEMS_V3 = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    tabs: [],
    hasGenericTabs: true,
    categoryId: null,
    key: 'all'
  },
  {
    id: FILTER_IDS.CHEF_SKU_PROMOTION,
    name: 'Add & Save',
    pattern: 'discount_by_sku',
    label: 'Add & Save',
    image: AddAndSave,
    withTabs: false,
    mealTypeFilter: true,
    fieldToFilter: 'filter_by',
    tabs: [],
    hasGenericTabs: true,
    key: 'add-and-save'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_HEALTHY,
    name: 'Wellness',
    label: 'Wellness',
    image: Healthy,
    withTabs: false,
    pattern: 'healthy',
    hasGenericTabs: true,
    categoryId: null,
    tabs: [],
    key: 'wellness',
    mealTypeFilter: true,
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.FAVORITES,
    name: 'Favorites',
    pattern: 'favorite',
    label: 'Favorites',
    image: Favorites,
    isFavorite: true,
    tabs: [],
    hasGenericTabs: true,
    categoryId: null,
    key: 'favorites'
  },
  {
    id: FILTER_IDS.YOUVE_TRIED,
    name: 'Order Again',
    pattern: 'alreadyRequested',
    label: 'Order Again',
    image: YouveTried,
    tabs: [],
    hasGenericTabs: true,
    categoryId: null,
    key: 'youve-tried'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SALADS_AND_BOWLS,
    name: 'Salads/Bowls',
    pattern: 'Salads & Bowls',
    label: 'Salads/Bowls',
    image: Salad,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'salads-bowls',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_BALANCED_PLATE,
    name: 'Balanced',
    pattern: 'Balanced Plate',
    label: 'Balanced',
    image: Balanced,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'balanced',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.BREAKFAST,
    name: 'Breakfast',
    pattern: '',
    label: 'Breakfast',
    image: Breakfast,
    tabs: BREAKFASTS_MENU_ITEMS_TABS,
    hasGenericTabs: false,
    categoryId: 3110,
    key: 'breakfast'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PASTA_AND_NOODLES,
    name: 'Pasta',
    pattern: 'Pasta & Noodles',
    label: 'Pasta',
    image: Noodles,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'pasta',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_FAMILY_FAVORITES,
    name: 'Family Favorites',
    pattern: 'family_favorites',
    label: 'Family Favoritess',
    image: FamilyFavorites,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'family_favorites',
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_TACOS_AND_BURRITOS,
    name: 'Tacos/Burritos',
    pattern: 'Tacos & Burritos',
    label: 'Tacos/Burritos',
    image: Taco,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'tacos-burritos',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.SIDES_EXPERIMENT,
    name: 'Mix & Match',
    pattern: 'sides_without_proteins',
    label: 'Mix & Match',
    image: Sides,
    tabs: SIDES_EXPERIMENT_MENU_ITEMS_TABS,
    hasGenericTabs: false,
    key: 'sides',
    mealTypeFilter: true,
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.SIDES_PROTEIN_ONLY,
    name: 'Protein Only',
    pattern: 'sides_with_proteins',
    label: 'Protein Only',
    image: Protein,
    tabs: [],
    hasGenericTabs: true,
    mealTypeFilter: true,
    key: 'protein_only',
    fieldToFilter: 'filter_by'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_VEG_MEALS,
    name: 'Veggie',
    pattern: 'Veg Meals',
    label: 'Veggie',
    image: Veggie,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'veg-meals',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SANDWICH_AND_WRAPS,
    name: 'Sandwiches',
    pattern: 'Sandwich & Wraps, Burgers',
    label: 'Sandwiches',
    image: Burgers,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'sandwiches',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PROTEIN_STARCH,
    name: 'Protein/Starch',
    pattern: 'Protein / Starch',
    label: 'Protein/Starch',
    image: ProteinStarch,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'protein-starch',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PIZZA,
    name: 'Pizza',
    pattern: 'Pizza',
    label: 'Pizza',
    image: Pizza,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true,
    categoryId: null,
    key: 'pizza',
    fieldToFilter: 'typesTags'
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SOUPS_AND_STEWS,
    name: 'Soups/Stews',
    pattern: 'Soups & Stews',
    label: 'Soups/Stews',
    image: Soup,
    tabs: [],
    hasGenericTabs: true,
    mealTypeFilter: true,
    categoryId: null,
    key: 'soups-stews',
    fieldToFilter: 'typesTags'
  }
]

export const MEAL_TYPES_LABEL = {
  PIZZA: 'Pizza',
  SALADS_AND_BOWLS: 'Salads & Bowls',
  SANDWICH_AND_WRAPS: 'Sandwich & Wraps',
  TACOS_AND_BURRITOS: 'Tacos & Burritos',
  PASTA_AND_NOODLES: 'Pasta & Noodles',
  PROTEIN_STARCH: 'Protein / Starch',
  VEG_MEALS: 'Veg Meals',
  BALANCED_PLATE: 'Balanced Plate',
  BURGERS: 'Burgers',
  SOUPS_AND_STEWS: 'Soups & Stews',
  BREAKFAST: 'Breakfast'
}

export const ALL_SIDES_SUB_FILTER = {
  id: FILTER_IDS.SIDES_ALL,
  image: Sides,
  name: 'All',
  label: 'All',
  pattern: 'sides_without_proteins',
  fieldToFilter: 'filter_by'
}

const BETA_TAG_DATA = {
  label: 'BETA',
  styles: {
    'background-color': '#97225A',
    'font-size': '10px',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': 10,
    color: '#fff',
    padding: '0 4px'
  }
}

export const FOR_YOU_FILTER = {
  id: FILTER_IDS.FOR_YOU,
  name: 'For you',
  label: 'For you',
  image: Foryou,
  pattern: 'foryou',
  tabs: [],
  hasGenericTabs: true,
  tags: [BETA_TAG_DATA],
  key: 'for-you'
}

export const GREEN_EATING_FILTER = {
  id: FILTER_IDS.MEAL_TYPE_GREEN_EATING,
  name: 'Green eating',
  label: 'Green eating',
  image: GreenEating,
  withTabs: false,
  pattern: 'green_eating',
  hasGenericTabs: true,
  categoryId: null,
  tabs: [],
  key: 'green_eating',
  mealTypeFilter: true,
  fieldToFilter: 'filter_by'
}

export const GENERIC_FOR_YOU_TAB = {
  id: FILTER_IDS.FOR_YOU,
  name: 'For you',
  label: 'For you',
  image: Foryou,
  hasSub: true,
  pattern: 'foryou',
  withTabs: false,
  key: 'for-you'
}

export const DEALS_TAG_STYLES = {
  'background-color': '#3939D8',
  'font-size': '10px',
  'font-style': 'normal',
  'font-weight': 700,
  'line-height': 10,
  color: '#fff',
  padding: '2px'
}
