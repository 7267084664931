export const subscriptionBackQuery = `
query getPersonalizedMenu($date: String!) {
  personalized(date: $date) {
    available
    categories {
      id
      title
      label
      subtitle
      tag
      cover_title
      cover_text
      cover_image
      cover_image_mobile
      merchandised_new {
        id
        title
        description
        authorId
        authorName
        authorPhoto
        coverPhoto
        position
        isAutomaticCreated
        color
        items {
          entity_id
          name
        }
      }
      meals {
        entity_id
        batch_id
        name
        short_description
        calories
        image
        image_path
        price
        is_premium
        premium_fee
        premium_special
        sku
        entity_id
        category_id
        chef_id
        chef_firstname
        chef_lastname
        is_celebrity_chef
        feature {
          name
          description
          icon
          color
          background
        }
        nutritional_facts {
          calories
          fat
          servingsize
          carbs
          fiber
          protein
          saturated_fat
          sodium
          sugar
        }
        logopic
        bannerpic
        stock
        inStock
        qty
        meat_type
        filter_by
        stars
        reviews
        source
        user_rating
        warning
        warnings {
          message
          restrictions_applied {
            name
          }
        }
        weight
        sidedish {
          id
        }
        specifications_detail {
          label
        }
        ingredients_data {
          entity_id
          name
          value
        }
      }
    }
  }
}
`
export const menuServiceQuery = `
    query getMenu($date: String!, $filters: MenuFilters) {
      menu(date: $date, filters: $filters) {
        categories{
            id
            title
            subtitle
            label
            tag
            icon
            coverInfo {
                title
                text 
                image
                imageMobile
                imageMacro
            }
        }
        meals {
          id
          batchId
          name
          shortDescription
          image
          imagePath
          primaryImageUrl
          price
          finalPrice
          premiumFee
          premiumSpecial
          sku
          source
          stock
          isNewMeal
          isBackInTheMenu
          isMealRestaurantExclusive
          hasRecipe
          userRating
          showInBundlesOnly
          warning
          warnings {
            message
            restrictionsApplied {
              name
            }
            dietsNotMatching {
              name
            }
            allergensNotMatching {
              name
            }
          }
          dietsMatching {
            name
          }
          reviews
          stars
          qty
          categoryId
          category{
              id
              title
              label
          }
          chef{
              id
              firstName
              lastName
              bannerPic
              profileImageUrl
          }
          meatType
          meatCategory
          nutritionalFacts{
              calories
              fat
              carbs
              sodium
              fiber
          }
          specificationsDetails{
              label
          }
          sideDish{
              id
              name
          }
          feature {
            name
            description
            icon
            color
            background
          }
          weight
          filter_by
          cuisines
          sidesSubCategoryNames
          media {
            secondaryImage
          }
          categories {
            label
            id
          }
          relatedMeal {
            id
            categoryId
            name
            shortDescription
            image
            imagePath
            price
            premiumFee
            premiumSpecial
            sku
            stock
            isNewMeal
            userRating
            warning
            batchId
          }
          typesTags
          tags {
            type
            name
          }
          inventoryId
          promotions {
            amount {
              value
              type
            }
            type
            constraints {
              categoryId
              subCategoryId
              capAmount {
                type
                value
              }
            }
          }
        }
        bundles {
          inventoryId
          name
          subtitle
          description
          stock
          image
          sku
          price
          finalPrice
          priceWithoutPlanMeal
          originalPriceWithoutPlanMeal
          isNewBundle
          filter_by
          meals {
            mealExternalId
          }
          categories {
            label
            id
          }
          category{
            id
            label
          }
        }
        promotions {
          amount {
            value
            type
          }
          type
          constraints {
            categoryId
            subCategoryId
            capAmount {
              type
              value
            }
          }
        }
        filters{
          selectedChefs
        }
        sorting {
          type
          sort
          sortedMealBundles
        }
      }
    }
  `
export const getMealMenuServiceQuery = `
query getMeal($id: Int!) {
  meal(id: $id) {
    id
    name
    sku
    image
    imagePath
    smallImage
    thumbnail
    stars
    mealStory
    allergenicStatement
    isNewMeal
    isCarbonNeutral
    nutritionalFacts {
        calories
        servingSize
        fat
        carbs
        fiber
        protein
        sodium
        saturatedFat
        sugar
    }
    cookingSteps {
        microwaveSteps
        ovenSteps
    }
    categoryId
    category{
        id
    }
    categories {
      label
      id
    }
    chef{
        id
        firstName
        lastName
        bannerPic
        profileImageUrl
    }
    assortment
    specificationsDetails{
      id
      label
      image
    }
    price
    finalPrice
    premiumSpecial
    premiumFee
    reviews
    userReviews{
      id
      customerFirstName
      customerLastName
      feedback
      rating
      createdAt
    }
    ingredientsStatement
    shortDescription
    media {
      detailsImage
      detailsImageMobile
    }
    detailsTitle
    detailsDescription
    worksWellWith {
      description
      image
    }
    feature {
      name
    }
    meatType
    recipes {
      id
      chef_name
      chef_id
      image_url
      title
      description
      chef_image_url
      tags
      servings
      tools_needed
      ingredients
      preparation_time
      cooking_time
      steps
    }
    heatingInstructionsVideos {
      name
      path
    }
    promotions {
      amount {
        value
        type
      }
      type
      constraints {
        categoryId
        subCategoryId
        capAmount {
          type
          value
        }
      }
    }
  }
}
`

export const getMerchandiseMealsQuery = `
query getCollections($date: String!) {
  collections(date: $date) {
    id
    title
    description
    authorId
    authorName
    authorPhoto
    coverPhoto
    position
    isAutomaticCreated
    color
    hideInSubscriptionHome
    __typename
  }
}

`

export const getMenuCollectionsQuery = `
query getCollections($date: String!) {
  collections(date: $date) {
    id
    title
    description
    authorId
    authorName
    authorPhoto
    heroPhoto
    coverPhoto
    position
    isAutomaticCreated
    color
    hideInSubscriptionHome
    meals {
        id
        batchId
          name
          shortDescription
          image
          imagePath
          price
          primaryImageUrl
          finalPrice
          premiumFee
          premiumSpecial
          sku
          stock
          isNewMeal
          hasRecipe
          userRating
          showInBundlesOnly
          warning
          warnings {
            message
            restrictionsApplied {
              name
            }
            dietsNotMatching {
              name
            }
            allergensNotMatching {
              name
            }
          }
          categories {
            label
            id
          }
          dietsMatching {
            name
          }
          reviews
          stars
          qty
          categoryId
          category{
              id
              title
          }
          chef{
              id
              firstName
              lastName
              bannerPic
              logoPic
              profileImageUrl
          }
          meatType
          meatCategory
          nutritionalFacts{
              calories
          }
          specificationsDetails{
              label
          }
          sideDish{
              id
              name
          }
          feature {
            name
            description
            icon
            color
            background
          }
          weight
          filter_by
          cuisines
          relatedMeal {
              id
              categoryId
              name
              shortDescription
              image
              imagePath
              price
              premiumFee
              premiumSpecial
              sku
              stock
              isNewMeal
              userRating
              warning
              batchId
          }
          tags {
            type
            name
          }
          inventoryId
          promotions {
            amount {
              value
              type
            }
            type
            constraints {
              categoryId
              subCategoryId
              capAmount {
                type
                value
              }
            }
          }
      __typename
    }
    bundles {
      inventoryId
      name
      description
      subtitle
      stock
      image
      sku
      price
      finalPrice
      priceWithoutPlanMeal
      originalPriceWithoutPlanMeal
      isNewBundle
      meals {
        id
        categoryId
        chef {
          id
          firstName
          lastName
          bannerPic
          profileImageUrl
        }
        stock
      }
    }
    promotions {
      amount {
        value
        type
      }
      type
      constraints {
        categoryId
        subCategoryId
        capAmount {
          type
          value
        }
      }
    }
    __typename
  }
}

`

export const getRecipeQuery = `
query getRecipe($id: Int!) {
  recipe(id: $id) {
    id
    chef_name
    chef_id
    image_url
    mealExternalId
    title
    description
    chef_image_url
    tags
    servings
    tools_needed
    ingredients
    preparation_time
    cooking_time
    steps
  }
}
`

export const fastOrderingUserLastOrder = `
query meals($date: String!) {
  userLastOrder(date: $date) {
    meals {
      id
      batchId
      quantity
      inventoryId
    },
    cartStatus
  }
}
`

export const fastOrderingUserRecommendation = `
query meals($date: String!) {
  recommendationOnDemand(date: $date) {
    meals {
      id
      batchId
      quantity
      inventoryId
    },
    cartStatus
  }
}
`

export const getCrossSellingItems = `
  query getCrossSellingItems($date: String!, $cartItems: [CartItem!]) {
    crossSellingItems(date: $date, cartItems: $cartItems) {
      title
      subTitle
      category 
      meals {
        id
      }
    }
  }`

export const getMealsLite = `
  query getMealsLite($id: String!) {
    mealsLite(id: $id) {
      bundle{
        name
        subtitle
        image
        sku
        price
        finalPrice
        priceWithoutPlanMeal
        originalPriceWithoutPlanMeal
        description
        meals{
          mealExternalId
          name
          primaryImageUrl
          categoryId
          warnings {
            message
            restrictionsApplied {
              name
            }
            dietsNotMatching {
              name
            }
            allergensNotMatching {
              name
            }
          }
          chef{
            id
            firstName
            lastName
            profileImageUrl
          }
          specificationsDetails{
            id
            label
            image
          }
        }
      }
      meal{
        id
        name
        sku
        image
        imagePath
        stars
        mealStory
        price
        finalPrice
        allergenicStatement
        isNewMeal
        isCarbonNeutral
        nutrients {
          name
          unit
          value
          dailyValue
        }
        servingSize
        servingsPerPackage
        nutritionalFacts {
            calories
            servingSize
            fat
            carbs
            fiber
            protein
            sodium
            saturatedFat
            sugar
        }
        cookingSteps {
            microwaveSteps
            ovenSteps
        }
        categoryId
        category{
            id
        }
        categories {
          label
          id
        }
        chef{
            id
            firstName
            lastName
            bannerPic
            profileImageUrl
        }
        assortment
        specificationsDetails{
          id
          label
          image
        }
        price
        premiumSpecial
        premiumFee
        reviews
        userReviews{
          id
          customerFirstName
          customerLastName
          feedback
          rating
          createdAt
        }
        ingredientsStatement
        shortDescription
        media {
          detailsImage
          detailsImageMobile
        }
        detailsTitle
        detailsDescription
        worksWellWith {
          description
          image
        }
        feature {
          name
        }
        meatType
        recipes {
          id
          chef_name
          chef_id
          image_url
          title
          description
          chef_image_url
          tags
          servings
          tools_needed
          ingredients
          preparation_time
          cooking_time
          steps
        }
        heatingInstructionsVideos {
          name
          path
        }
        finalPrice
        promotions {
          amount {
            value
            type
          }
          type
          constraints {
            categoryId
            subCategoryId
            capAmount {
              type
              value
            }
          }
        }
      }
    }
  }
  `
