import { useEffect } from 'react'
import { useAtom } from 'jotai'

import { ResponsiveModalOrBottomSheet } from 'src/components/ResponsiveModalOrBottomSheet/ResponsiveModalOrBottomSheet'
import { MembershipIntroduction } from './components/MembershipIntroduction/MembershipIntroduction'
import { MembershipConfirmation } from './components/MembershipConfirmation/MembershipConfirmation'
import { MembershipBenefits } from './components/MembershipBenefits/MembershipBenefits'
import { MembershipCheckout } from './components/MembershipCheckout/MembershipCheckout'
import { MembershipCancel } from './components/MembershipCancel/MembershipCancel'
import { membershipModalAtom } from 'src/atoms/modals/membershipModal.atom'
import { useMembershipData } from 'src/api/Membership/useMembershipData'
import { useMarkModalAsViewed } from 'src/api/Modal/markModalAsViewed'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from './useTrackMembershipModal'
import { ModalType } from 'src/types/nextModal'
import { Step } from 'src/types/membership'

export function MembershipModal({ upselling = false }) {
  const [{ isOpen, step }, setMembership] = useAtom(membershipModalAtom)
  const { membershipData, refetch, loading } = useMembershipData()
  const { viewModal } = useMarkModalAsViewed()
  const { trackModalClosed } = useTrackMembershipModal({ overlayName: step })

  function handleClose() {
    trackModalClosed()
    setMembership(prev => ({
      ...prev,
      isOpen: false,
      step: MEMBERSHIP_MODAL_STEPS.closed
    }))
  }

  function handleContinue(step: Step, isRejoin: boolean = false) {
    setMembership(prev => ({ ...prev, step, isRejoin }))
  }

  useEffect(() => {
    if (upselling) {
      setMembership(prev => ({
        ...prev,
        step: MEMBERSHIP_MODAL_STEPS.introduction,
        isOpen: true
      }))
      viewModal({
        type: ModalType.MembershipModal
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResponsiveModalOrBottomSheet
      className="membership"
      onClose={handleClose}
      isOpen={isOpen}>
      {step === MEMBERSHIP_MODAL_STEPS.introduction && (
        <MembershipIntroduction
          membershipData={membershipData}
          onContinue={handleContinue}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.benefits && (
        <MembershipBenefits
          membershipData={membershipData}
          onContinue={handleContinue}
          onClose={handleClose}
          loading={loading}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.checkout && (
        <MembershipCheckout
          membershipData={membershipData}
          onContinue={handleContinue}
          refetch={refetch}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.confirmation && (
        <MembershipConfirmation onClose={handleClose} />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.cancel && (
        <MembershipCancel
          membershipData={membershipData}
          onContinue={handleContinue}
          onClose={handleClose}
          refetch={refetch}
        />
      )}
    </ResponsiveModalOrBottomSheet>
  )
}
